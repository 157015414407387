import {trackSignUpClick} from 'utils/analytics';
import {Carousel} from './Carousel';

const CloudTestimonials = () => {
  return (
    <div className="container mx-auto">
      <div className="lg:gap-25 flex w-full flex-col gap-12 px-4 py-12 lg:flex-row lg:py-32">
        <div className="flex shrink-0 flex-col items-center  gap-8 lg:w-[468px]  lg:items-start">
          <div className="text-2.5xl lg:text-4.5xl text-center font-semibold text-gray-900 lg:text-left">
            Loved by engineers and companies
          </div>
          <div className="text-center font-sans text-base text-gray-800 lg:text-left">
            Harness the power of continuous profiling to uncover hidden performance issues, analyze
            code execution, and optimize your applications in real-time.
          </div>
          <a
            href="https://cloud.polarsignals.com"
            className="w-fit rounded bg-gray-800 px-10 py-3 font-sans text-base font-medium text-white first-line:text-center hover:opacity-90 lg:py-4 lg:text-lg"
            onClick={() => trackSignUpClick('testimonials-cta')}
          >
            Start profiling
          </a>
        </div>
        <Carousel />
      </div>
    </div>
  );
};

export default CloudTestimonials;
